const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://tprivi.dvgeo.app' : 'http://192.168.100.106:3032',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://tprivi.dvgeo.app' : 'http://192.168.100.106:3032',
    api: 'api/',
    apiSocket: 'tprivi::1.2',
    nameDir: 'tprivi',
    package: 'app.dvgeo.tprivi.web',
    version: '1.0.1',
    googleAPIKey: 'AIzaSyCs3PBW7wbwlqRIH7Fb9Osx6I2h3ZETxRI',
    appName: 'TPrivi',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#026247',
    colorDark: '#060605',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.tprivi.passenger',
    playStoreDriverId: 'app.dvgeo.tprivi.driver',
    appStorePassengerId: '6740870946',
    appStoreDriverId: '6740870948',
    email: "J.carlos.tp03@gmail.com",
};
export default config;
